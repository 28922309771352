import axios, { AxiosRequestConfig } from "axios";
import qs from "qs";

export const fetchDataFromCMS = async <T>(
  endpoint: string,
  accessToken?: string | null,
  urlParamsObject?: Record<string, any>,
  options?: AxiosRequestConfig,
  impersonate?: string,
  onSuccessCallback?: () => void,
  onErrorCallback?: () => void,
): Promise<{ data: T | null; error: any | null }> => {
  const baseURL = process.env.GATSBY_API_URL || "";

  const headers = {
    ...(options?.headers || {}),
    "northstar-identity": "okta",
    "northstar-authorization": `Bearer ${accessToken}`,
    ...(impersonate && { "northstar-impersonate": impersonate }),
  };

  const axiosOptions: AxiosRequestConfig = {
    ...options,
    headers,
  };

  const queryString = urlParamsObject
    ? `?${qs.stringify(urlParamsObject)}`
    : "";
  const url = new URL(`${endpoint}${queryString}`, new URL(baseURL)).href;

  try {
    const response = await axios(url, axiosOptions);
    if (onSuccessCallback) onSuccessCallback();
    return { data: response.data, error: null };
  } catch (error) {
    if (onErrorCallback) onErrorCallback();
    return { data: null, error: error };
  }
};
