import React, { useState } from "react";
import { TickerAttributes } from "../../types/cms";
import AutocompleteDropdown from "../AutocompleteDropdown";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import { Chip } from "@mui/material";

interface ReportTickerFilterProps {
  tickers: TickerAttributes[];
  selectedTickers: TickerAttributes[];
  setSelectedTickers: (tickers: TickerAttributes[]) => void;
  loading: boolean;
  className?: string;
}

const ReportTickerFilter: React.FC<ReportTickerFilterProps> = ({
  tickers,
  selectedTickers,
  setSelectedTickers,
  loading,
  className = "",
}) => {
  const handleTickersChange = (
    _event: React.SyntheticEvent<Element, Event>,
    newValue: TickerAttributes[],
    _reason: string,
  ) => {
    setSelectedTickers(newValue);
  };

  const customFilterOptions = (
    options: TickerAttributes[],
    { inputValue }: { inputValue: string },
  ): TickerAttributes[] => {
    return options.filter((option) => {
      const tickerMatch = option.ticker
        .toLowerCase()
        .includes(inputValue.toLowerCase());
      const companyNameMatch = option.company_name
        .toLowerCase()
        .includes(inputValue.toLowerCase());
      return tickerMatch || companyNameMatch;
    });
  };

  return (
    <div className={clsx(className)}>
      <AutocompleteDropdown
        label=""
        placeholder="Search for a ticker or company"
        value={selectedTickers}
        onChange={handleTickersChange}
        isOptionEqualToValue={(option, value) => option.ticker === value.ticker}
        options={tickers.map((ticker) => ({
          label: ticker.ticker,
          value: ticker,
        }))}
        filterOptions={customFilterOptions}
        renderOption={(props, option) => (
          <Box component="li" {...props} sx={{ display: "flex" }}>
            <Typography
              sx={{ width: "6rem", color: "rgb(17,24,39)", fontWeight: "600" }}
            >
              {option.ticker}
            </Typography>
            <Typography
              sx={{
                flex: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                color: "rgb(55,65,81)",
              }}
            >
              {option.company_name}
            </Typography>
          </Box>
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={`${option.ticker}`}
              // Pass the index directly to getTagProps as per MUI's signature
              {...getTagProps({ index })}
            />
          ))
        }
        loading={loading}
      />
    </div>
  );
};

export default ReportTickerFilter;
