import clsx from "clsx";
import React from "react";
import SelectDropdown from "../SelectDropdown";
import { SelectChangeEvent } from "@mui/material";
import { SortingMethod } from "../../utils";

interface ReportSortProps {
  selectedSort: SortingMethod;
  setSelectedSort: (sort: SortingMethod) => void;
  className?: string;
}

interface Option {
  name: string;
  value: SortingMethod;
}

const sortOptions: Option[] = [
  { name: "Market Cap", value: "market_cap" },
  { name: "Report Date", value: "report_date" },
];

const ReportSort: React.FC<ReportSortProps> = ({
  selectedSort,
  setSelectedSort,
  className = "",
}) => {
  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedSort(event.target.value as SortingMethod);
  };
  return (
    <div className={clsx(className)}>
      <SelectDropdown
        label="Sort by"
        value={selectedSort}
        onChange={handleChange}
        options={sortOptions}
      />
    </div>
  );
};

export default ReportSort;
