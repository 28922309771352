import React from "react";
import { DocumentAttributes, TickerAttributes } from "../../types/cms";
import { Link } from "gatsby";
import { getFormattedReportMetada } from "../../utils";

interface ReportCardProps {
  document: DocumentAttributes;
  associated_ticker?: TickerAttributes;
  id: string;
}

const ReportCard: React.FC<ReportCardProps> = ({
  document,
  associated_ticker,
  id,
}) => {
  const {
    encodedReportId,
    ticker,
    companyName,
    reportingPeriod,
    overview,
    logo_url,
  } = getFormattedReportMetada(document, associated_ticker);
  return (
    <Link to={`/company_research/report?id=${id}`}>
      <div className="flex flex-col w-full h-full overflow-hidden px-4 py-3 bg-white hover:shadow-lg font-roboto">
        <div className="flex items-center h-12 xs:h-14">
          <h2 className="flex-1 font-bold text-orange text-4xl">{ticker}</h2>
        </div>
        <div className="flex flex-col">
          <div className="line-clamp-2 font-semibold text-2xl text-gray-900">
            {companyName}
          </div>
          <div className="mt-2 xs:mt-3 text-gray-800">{reportingPeriod}</div>
        </div>
        <div className="my-2 xs:my-3 pb-1 line-clamp-3 text-sm text-gray-600">
          {overview}
        </div>
      </div>
    </Link>
  );
};

export default ReportCard;
