// AutocompleteDropdown.tsx
import React from "react";
import {
  Autocomplete as MuiAutocomplete,
  TextField,
  Chip,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  FilterOptionsState,
  AutocompleteRenderGetTagProps,
} from "@mui/material";

interface Option<T> {
  label: string;
  value: T;
}

interface AutocompleteDropdownProps<T> {
  label: string;
  placeholder: string;
  value: T[];
  options: Option<T>[];
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    newValue: T[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<T>,
  ) => void;
  isOptionEqualToValue?: (option: T, value: T) => boolean;
  filterOptions?: (options: T[], state: FilterOptionsState<T>) => T[];
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: T,
  ) => React.ReactNode;
  renderTags?: (
    value: T[],
    getTagProps: AutocompleteRenderGetTagProps,
  ) => React.ReactNode[];
  loading?: boolean;
}
// TODO: Don't like mixing MUI and Tailwind, but needed a proper Combobox for search, autocomplete, and tag display.
const AutocompleteDropdown = <T extends unknown>({
  label,
  placeholder,
  value,
  onChange,
  isOptionEqualToValue,
  options,
  filterOptions,
  renderOption,
  renderTags,
  loading,
}: AutocompleteDropdownProps<T>) => (
  <MuiAutocomplete
    multiple
    id={`autocomplete-${label}`}
    options={options.map((option) => option.value)}
    value={value}
    onChange={onChange}
    isOptionEqualToValue={isOptionEqualToValue}
    getOptionLabel={(option) => {
      const foundOption = options.find((o) => o.value === option);
      return foundOption ? foundOption.label : String(option);
    }}
    filterOptions={filterOptions}
    renderOption={renderOption}
    renderInput={(params) => (
      <TextField
        {...params}
        variant="outlined"
        label={label}
        placeholder={placeholder}
        sx={{
          ".MuiOutlinedInput-root": {
            borderRadius: "0px",
            bgcolor: "white",
          },
        }}
      />
    )}
    renderTags={(tagValue, getTagProps) =>
      renderTags
        ? renderTags(tagValue, getTagProps)
        : tagValue.map((option, index) => (
            <Chip
              label={String(option)} // Fallback option, should be customized by parent component
              {...getTagProps({ index })}
            />
          ))
    }
    loading={loading}
    sx={{
      "& .MuiAutocomplete-tag": {
        borderRadius: "0px",
        margin: "2px",
      },
    }}
  />
);

export default AutocompleteDropdown;
