import React, { useEffect, useState } from "react";
import BannerComponent from "../../components/Banner";
import LoadingStatus from "../../components/LoadingStatus";

import NorthstarPageLayout from "../../components/NorthstarPageLayout";
import ReportFilter from "../../components/ReportFilter";
import ReportList from "../../components/ReportList";
import { useAuthContext } from "../../contexts/authContext";
import { useDocuments } from "../../hooks/documentsHook";
import useFetchAllTickers from "../../hooks/tickersHook";
import { SCOPES } from "../../constants";

const CompanyResearch: React.FC = () => {
  const { accessToken, clearIdentity } = useAuthContext();

  const {
    tickers: tickersData,
    isLoading: isLoadingTickers,
    error: errorLoadingTickers,
  } = useFetchAllTickers();

  const tickers = tickersData?.map((ticker) => ticker.attributes) || [];

  const {
    isLoadingInitial,
    error: errorLoadingDocuments,
    documents,
    selectedTickers,
    setSelectedTickersWithReset,
    selectedPeriodFilter,
    setSelectedPeriodFilterWithReset,
    selectedSort,
    setSelectedSortWithReset,
    hasMore,
    loadMoreDocuments,
    isLoadingMore,
  } = useDocuments();

  useEffect(() => {
    let ignore = true;
    if (errorLoadingDocuments?.status === 401 && !ignore && clearIdentity) {
      clearIdentity();
    }
    return () => {
      ignore = false;
    };
  }, [errorLoadingDocuments?.status]);

  let reportListTemplate = <LoadingStatus loadingType="reports_loading" />;
  if (isLoadingInitial) {
    reportListTemplate = <LoadingStatus loadingType="reports_loading" />;
  } else if (errorLoadingDocuments) {
    reportListTemplate = <LoadingStatus loadingType="reports_error" />;
  } else if (documents.length === 0) {
    reportListTemplate = <LoadingStatus loadingType="reports_empty" />;
  } else {
    reportListTemplate = (
      <ReportList
        reports={documents}
        hasMore={hasMore}
        loadMore={loadMoreDocuments}
        isLoadingMore={isLoadingMore}
      />
    );
  }

  return (
    <NorthstarPageLayout
      title="Company Research"
      className="overflow-y-scroll"
      requiredScope={SCOPES.COMPANY_RESEARCH}
    >
      <>
        {/* <Spinner active={isLoading} /> */}
        <BannerComponent
          title="Company Research"
          description="Welcome to BAM Elevate's AI-enabled research platform created exclusively for our portfolio companies. This tool leverages AI and our research library to generate robust company research."
        />
        <ReportFilter
          tickers={tickers}
          selectedTickers={selectedTickers}
          setSelectedTickers={setSelectedTickersWithReset}
          selectedPeriodFilter={selectedPeriodFilter}
          setSelectedPeriodFilter={setSelectedPeriodFilterWithReset}
          selectedSort={selectedSort}
          setSelectedSort={setSelectedSortWithReset}
          loadingTickers={isLoadingTickers}
        />
        {reportListTemplate}
      </>
    </NorthstarPageLayout>
  );
};

export default CompanyResearch;
