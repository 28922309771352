import { useCallback, useEffect, useMemo, useState } from "react";
import {
  CMSAPIDataCollection,
  CMSAPIDataItem,
  DocumentAttributes,
  TickerAttributes,
} from "../types/cms";
import {
  PeriodKey,
  SortingMethod,
  getPeriodStartDate,
  getSortingParams,
} from "../utils";
import { AxiosResponse } from "axios";
import { fetchDataFromCMS } from "../cms-api";
import { useAuthContext } from "../contexts/authContext";

export interface InsightsDocumentAPIItem
  extends CMSAPIDataItem<DocumentAttributes> {
  associated_ticker?: CMSAPIDataItem<TickerAttributes>;
}

const PAGE_SIZE = 36;

export const useDocuments = () => {
  const [isLoadingInitial, setIsLoadingInitial] = useState<boolean>(true);
  const [documents, setDocuments] = useState<InsightsDocumentAPIItem[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [selectedTickers, setSelectedTickers] = useState<TickerAttributes[]>(
    [],
  );
  const [selectedPeriodFilter, setSelectedPeriodFilter] =
    useState<PeriodKey>("all_time");
  const [selectedSort, setSelectedSort] = useState<SortingMethod>("market_cap");
  const [error, setError] = useState<AxiosResponse<any, any> | null>(null);

  const { accessToken, impersonation } = useAuthContext();

  const queryParams = useMemo(() => {
    const sortingParams = getSortingParams(selectedSort);
    const params: Record<string, any> = {
      sort: sortingParams,
      fields: [
        "id",
        "report_id",
        "ticker",
        "company_name",
        "report_date",
        "report_start_date",
        "jsondocument",
        "logo_url",
      ],
      populate: {
        associated_ticker: {
          fields: [
            "ticker",
            "company_name",
            "market_cap",
            "logo_url",
            "featured",
          ],
        },
      },
      filters: {},
      pagination: {
        pageSize: PAGE_SIZE,
        page: currentPage,
      },
    };
    if (selectedTickers.length) {
      params.filters["ticker"] = {
        $in: selectedTickers.map((ticker) => ticker.ticker),
      };
    }
    if (selectedPeriodFilter !== "all_time") {
      const periodStart = getPeriodStartDate(selectedPeriodFilter);
      const periodEnd = new Date(); // Use the current date as the end of the period
      params.filters["$or"] = [
        // Reports that start within the selected period
        {
          report_start_date: {
            $gte: periodStart.toISOString(),
            $lte: periodEnd.toISOString(),
          },
        },
        // Reports that end within the selected period
        {
          report_date: {
            $gte: periodStart.toISOString(),
            $lte: periodEnd.toISOString(),
          },
        },
        // Reports that started before and end after the selected period (spanning across it)
        {
          $and: [
            { report_start_date: { $lte: periodStart.toISOString() } },
            { report_date: { $gte: periodEnd.toISOString() } },
          ],
        },
      ];
    }
    return params;
  }, [currentPage, selectedTickers, selectedSort, selectedPeriodFilter]);

  const fetchDocuments = useCallback(async () => {
    try {
      const { data, error } = await fetchDataFromCMS<
        CMSAPIDataCollection<InsightsDocumentAPIItem>
      >(
        "/api/transcripts",
        accessToken,
        queryParams,
        {
          method: "get",
        },
        impersonation,
      );

      if (error) {
        throw error;
      }

      if (data) {
        if (currentPage === 1) {
          setDocuments(data.data);
        } else {
          setDocuments((prevDocuments) => [...prevDocuments, ...data.data]);
        }
        setHasMore(data.meta.pagination.pageCount > currentPage);
      }
    } catch (fetchError: any) {
      setError(fetchError);
    } finally {
      setIsLoadingInitial(false);
      setIsLoadingMore(false);
    }
  }, [
    accessToken,
    currentPage,
    selectedTickers,
    selectedSort,
    selectedPeriodFilter,
  ]);

  // Effect to handle initial load and reload when filters change
  useEffect(() => {
    if (isLoadingInitial && accessToken) {
      fetchDocuments();
    }
  }, [isLoadingInitial, fetchDocuments, accessToken]);

  // Effect to handle loading more documents
  useEffect(() => {
    if (isLoadingMore && hasMore && accessToken) {
      fetchDocuments();
    }
  }, [isLoadingMore, hasMore, fetchDocuments, accessToken]);

  const loadMoreDocuments = useCallback(() => {
    if (!hasMore || isLoadingMore) return;
    setIsLoadingMore(true);
    setCurrentPage((prevPage) => prevPage + 1);
  }, [hasMore, isLoadingMore]);

  const resetAndFetch = useCallback(() => {
    setIsLoadingInitial(true);
    setDocuments([]);
    setCurrentPage(1);
    setHasMore(true);
  }, []);

  const setSelectedTickersWithReset = useCallback(
    (tickers: TickerAttributes[]) => {
      setSelectedTickers(tickers);
      resetAndFetch();
    },
    [resetAndFetch],
  );

  const setSelectedPeriodFilterWithReset = useCallback(
    (period: PeriodKey) => {
      setSelectedPeriodFilter(period);
      resetAndFetch();
    },
    [resetAndFetch],
  );

  const setSelectedSortWithReset = useCallback(
    (sort: SortingMethod) => {
      setSelectedSort(sort);
      resetAndFetch();
    },
    [resetAndFetch],
  );

  return {
    documents,
    isLoadingInitial,
    error,
    selectedTickers,
    setSelectedTickersWithReset,
    selectedPeriodFilter,
    setSelectedPeriodFilterWithReset,
    selectedSort,
    setSelectedSortWithReset,
    hasMore,
    isLoadingMore,
    loadMoreDocuments,
  };
};
