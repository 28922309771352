import React from "react";
import { TickerAttributes } from "../../types/cms";
import ReportTickerFilter from "./ReportTickerFilter";
import ReportPeriodFilter from "./ReportPeriodFilter";
import { PeriodKey, SortingMethod } from "../../utils";
import ReportSort from "./ReportSort";

interface ReportFilterProps {
  tickers: TickerAttributes[];
  selectedTickers: TickerAttributes[];
  setSelectedTickers: (tickers: TickerAttributes[]) => void;
  selectedPeriodFilter: PeriodKey;
  setSelectedPeriodFilter: (period: PeriodKey) => void;
  selectedSort: SortingMethod;
  setSelectedSort: (sort: SortingMethod) => void;
  loadingTickers: boolean;
}

const ReportFilter: React.FC<ReportFilterProps> = ({
  tickers,
  selectedTickers,
  setSelectedTickers,
  selectedPeriodFilter,
  setSelectedPeriodFilter,
  selectedSort,
  setSelectedSort,
  loadingTickers,
}) => {
  return (
    <div className="flex flex-col md:flex-row w-full mt-4 xs:mt-5 sm:mt-6">
      <div className="sr-only">Filters</div>
      <ReportTickerFilter
        tickers={tickers}
        selectedTickers={selectedTickers}
        setSelectedTickers={setSelectedTickers}
        loading={loadingTickers}
        className="w-full md:w-2/3 lg:w-3/4 xl:w-[55rem] md:pr-3 lg:pr-4"
      />
      <ReportSort
        selectedSort={selectedSort}
        setSelectedSort={setSelectedSort}
        className="w-full md:w-1/3 lg:w-1/4 xl:w-[19rem] mt-3 xs:mt-4 md:mt-0"
      />
      {/* <ReportPeriodFilter
        selectedPeriodFilter={selectedPeriodFilter}
        setSelectedPeriodFilter={setSelectedPeriodFilter}
        className="w-full md:w-1/3 lg:w-1/4 xl:w-[19rem] mt-3 xs:mt-4 md:mt-0"
      /> */}
    </div>
  );
};

export default ReportFilter;
